@font-face {
    font-family: Oswald;
    src: url(../../fonts/Lato-Reg.ttf);
  }
body{background: #F5F5F5 !important;}
*{ font-family: Oswald;}
.text-center{text-align: center;}
.Header_Div{
    background: rgba(0,0,0,0.92);
    height: 68px;
    padding: 8px;
}

.btn-flag {
    width: 25px;
    background-size: cover;
    height: 25px;
    border-radius: 50px !important;
    border: 1px #fff solid !important;
    margin: 0 5px;
    background-position: center;
}
.btn-flag:hover {
    box-shadow: inset 1px 0px 7px 2px #fff;
}
.england{
    background-image: url(../../Media/englandflag.png);
}
.german{
    background-image: url(../../Media/german-flag.png);
}

.img-logo {
    width: 100%;
    max-width: 130px;
}
.navbar{padding: 0 !important;   line-height: 48px;}
.navbar-row {
    height: 83px;
    background: #ffffff;
    box-shadow: 1px 2px 4px 0px #ccc;
}

.nav-link{
    padding: .2rem 1rem!important;
    text-decoration: none;
    box-shadow: none;
   
}
.nav-link.active{border-bottom: 2px #ccc solid;}
.nav-item {

    margin: 0 10px;   
}
.nav-item a{color: #f5f5f7 !important;
    transition: color 0.1s;}
.nav-item a:hover {
    color: #ffffff ;
    text-decoration: none !important;
}

.header{  height: 500px;}
.header-section {
    position: absolute !important;
    top: 68px;
    left: 0;
    right: 0;
    height: 500px;
    width: 100% !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
    z-index: -100;
    background-repeat: round;
    background-size: cover;
    z-index: 500;
}

.Home_img {
    background-image: url('../../Media/ikad-11.png');
}

.logo_img {
    width: 100px;
}
.p-title {
    color: #a7a7af;
    font-size: 48px;
    font-weight: 700;
    position: absolute;
    top: 38%;
    left: 39%;
    text-shadow: -3px 0px 0px #995b8e, 0px 0px 4px #ffffff, 0px 0px 4px #ffffff, 0px 0px 4px #ffffff, 0px 0px 4px #ffffff, 0px 0px 4px #ffffff, 0px 0px 4px #ffffff, 0px 0px 4px #ffffff, 0px 0px 4px #ffffff, 0px 0px 4px #ffffff, 0px 0px 4px #ffffff, 0px 0px 4px #ffffff, 0px 0px 4px #ffffff, 0px 0px 4px #ffffff, 0px 0px 4px #ffffff, 0px 0px 4px #ffffff, 0px 0px 4px #ffffff, 0px 0px 4px #ffffff, 0px 0px 4px #ffffff, 0px 0px 4px #ffffff, 0px 0px 4px #ffffff, 0px 0px 4px #ffffff, 0px 0px 4px #ffffff, 0px 0px 4px #ffffff, 0px 0px 4px #ffffff, 0px 0px 4px #ffffff, 0px 0px 4px #ffffff, 1px -1px 4px #ffffff, -4px 1px 4px #ffffff;
}

.Joinus-title{float: none !important;}
.info-section{border-top: 3px #09deca solid;
    background: #ffff;
    padding: 10px 60px;}

.info-section  .img-section{    text-align: center;}
.info-section  .img-section img{width: 250px;}
.info-section .text-section p{font-size: 22px;
    font-weight: 700;
    color: #6d6d6d;
    display: inline-block;
    border-bottom: 3px #09deca solid;
    padding: 0 0 5px 0;
    float: left;}

    .info-section .text-section  span{    padding: 10px 20px;
    line-break: revert;
    white-space: pre-wrap;
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    float: left;}

.Service .info-section  .img-section{    text-align: center;}
.Service .info-section  .img-section img{width: 250px;}
.Service .info-section .text-section p{font-size: 22px;
    font-weight: 700;
    color: #6d6d6d;
    display: inline-block;
    border-bottom: 3px #09deca solid;
    padding: 0 0 5px 0;
    float:none; }

.Service .info-section .text-section  span{    padding: 0 50px;
    line-break: revert;
    white-space: pre-wrap;
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    float:none;}




.prov-section{    height: 400px;    margin-top: 50px;}
.prov_img {
        background-image: url('../../Media/ikad-s-01.png');
        position: relative;
        display: block;
        background-size: cover;
        background-repeat: round;
}
.prov-section .text-section{position: absolute;
    text-align: center;
    padding-top: 5%;}
    .prov-section .text-section p   {
        background: #00000057;
     color: #ffffff;
     font-size: 32px;
     font-weight: 700;
     text-shadow: 0px 0px 5px #000;
    }
    .prov-section .text-section span  {color: white;
        background: #00000057;
        padding: 5px;
        font-size: 20px;
        font-weight: 500;
        text-shadow: 0px 0px 3px #000;}
.prov-section .btn-section{display: block;
        margin-top: 7%;}

.Service-section{font-size: 28px;
    font-weight: 700;
    color: #565656;
    margin: 2% 0 1% 0;
text-align: center;}

.Service-section p{
    border-bottom: 3px #09deca solid;
}
.service-img{    width: 100%;
    border-radius: 5px;}
.card-section{    text-align: center;}
.card-section .card{margin:0 20px;}
.card-section .card img{
    width: 100%;
    height: 200px;
}
.card-section .card .card-text{height: 90px;
    overflow: hidden;}


.footer-p-title {
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 0rem !important;
}

.footer-p-text {
    color: #ffffff;
    margin-bottom: 3px !important;
}

.footer-div {
    background: #4D5866;
    height: 250px;
    margin-top: 3%;
    padding: 2%;
}
.footer-text {
    background: #eaeaea;
   
}
.btn-larg {
    padding: .5rem 1rem!important;
    font-size: 1.25rem!important;
    line-height: 1.5!important;
    border-radius: .3rem!important;
}

.join-us-title{    font-size: 22px;
    color: #6d6d6d;}
    .join-us-email{    font-size: 18px;}
.Address-section i{    color: #00939F;
    font-size: 72px;
}
.Address-section .title{    color: #00939F;
    font-size: 28px;
    font-weight: 600;}
.img-page{width:100%;}
.map-section{    height: 250px;}
.contact-title{font-size: 22px;
    font-weight: 600;
    color: #4d4d4d;}
    
 @media (max-width: 1280px){
        .info-section .img-section img {
            width: 200px;
        }
    }
 @media (max-width: 1024px){
    .info-section .img-section img {
        width: 200px;
    }
}
 @media (max-width: 800px){
    .navbar {
        padding: 0 !important;
        line-height: 24px;
    }
    .nav-link {
        padding: 0 !important;
        text-decoration: none;
        box-shadow: none;
    }
    .info-section .text-section span {
        padding: 10px 0px;
        line-break: anywhere;}
}
@media (max-width: 568px){
    
    .navbar-collapse {
        flex-basis: 100% !important;
        flex-grow: 1 !important;
        align-items: flex-start !important;       
        z-index: 1500 !important;
        background: #f5f5f5 !important;
        text-align: left;
    }
    .nav-item a {
        color: #616161 !important;
        transition: color 0.1s;
    }
    .nav-link.active {
        border-bottom: 2px #080808 solid;
        color: #000 !important;
    }
    .header {
        height: 200px;
    }
    .header-section {      
        height: 200px;
    }
   
    .p-title{    font-size: 28px;
        top: 41%;
        left: 24%;}
        .info-section{    text-align: center;
            border-top: 3px solid #09deca;
            background: #fff;
            padding: 10px 0;}
        .info-section .text-section p {
            margin-top: 10px;
        }
        .info-section .text-section span {
            padding: 10 5px;
            line-break: anywhere;
            white-space: pre-wrap;
            display: block;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            float: left;}
           
            .prov-section {
                height: 200px;
                margin-top: 50px;
            }
            .prov-section .text-section {
                padding-top: 0%;
            }
            .prov-section .text-section p {
                font-size: 22px;
                margin-bottom: 2px;
            }
            .prov-section .text-section span {
                line-height: 42px;
                font-size: 14px;
            }
            .btn-larg {
                padding: .375rem .75rem !important;
                font-size: 1rem !important;
                line-height: 1.5;
                border-radius: .25rem !important;
                font-size: 12px !important;
            }
            .footer-p-text {                
                font-size: 12px;
            }
            .footer-text {
                background: #eaeaea;
                font-size: 10px;
            }
}